import React, { useEffect } from "react";
import { select, scaleLinear, axisBottom, axisLeft, append, attr, call, easeQuadInOut, csv } from "d3";



const DATA3 = [
  {x:	525	,y:	75.2	},
  {x:	125	,y:	46.6	},
  {x:	123	,y:	31.9	},
  {x:	231	,y:	64.1	},
  {x:	97	,y:	35.0	},
  {x:	108	,y:	43.7	},
];

const DATA = [
  {x:	525	,y:	75.2	},
  {x:	125	,y:	46.6	},
  {x:	123	,y:	31.9	},
  {x:	231	,y:	64.1	},
  {x:	97	,y:	35.0	},
  {x:	108	,y:	43.7	},
];
const DATA2 = [
  {x:	185	,y:	42.6	},
  {x:	286	,y:	38.2	},
  {x:	193	,y:	45.2	},
  {x:	194	,y:	39.9	},
  {x:	735	,y:	80.2	},
  {x:	626	,y:	74.3	},
  {x:	1392 ,y:	98.4	},
  {x:	920	,y:	94.4	},
  {x:	222	,y:	48.7	},
  {x:	104	,y:	37.8	},
  {x:	114	,y:	51.5	},
  {x:	77	,y:	44.0	},
  {x:	81	,y:	31.2	},
  {x:	205	,y:	34.2	},
  {x:	199	,y:	38.2	},
  {x:	364	,y:	59.9	},
  {x:	755	,y:	77.5	},
  {x:	178	,y:	43.5	},
  {x:	141	,y:	49.7	},
  {x:	258	,y:	83.6	},
  {x:	881	,y:	95.7	},
  {x:	547	,y:	77.7	},
  {x:	133	,y:	64.8	},
  {x:	93	,y:	37.2	},
  {x:	56	,y:	37.0	},
  {x:	67	,y:	24.2	},
  {x:	189	,y:	46.7	},
  {x:	280	,y:	64.5	},
  {x:	136	,y:	49.2	},
  {x:	73	,y:	32.7	},
  {x:	96	,y:	32.6	},
  {x:	134	,y:	52.9	},
  {x:	70	,y:	43.5	},
  {x:	510	,y:	72.4	},
  {x:	82	,y:	31.4	},
  {x:	133	,y:	48.0	},
  {x:	175	,y:	47.8	},
  {x:	114	,y:	47.2	},
  {x:	107	,y:	46.1	},
  {x:	160	,y:	40.2	},
  {x:	145	,y:	67.8	}
];


export default function App() {
  useEffect(() => {
    const margin = { top: 10, right: 40, bottom: 30, left: 30 },
      width = 450 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;


      async function load() {
          const res = await csv("data.csv");
          return res;
      }
      const dataset = load()      


    const svg = 
      select("#area")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const x = 
      scaleLinear()
      .domain([0, 1400])
      .range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .transition()
      .call(axisBottom(x));

    const y = 
      scaleLinear()
      .domain([0, 100])
      .range([height, 0]);
      svg
      .append("g")
      .transition()
      .call(axisLeft(y));

    svg
      .selectAll("whatever")
      .data(DATA)
      .enter()
      .append("circle")
      .transition()
      .duration(1000)
      .delay(500)
      .ease(easeQuadInOut)
      .attr("cx", (d) => x(d.x))
      .attr("cy", (d) => y(d.y))
      .attr("r", 10)
      .style("fill", "purple");
      ;

    svg
      .selectAll("whatever")
      .data(DATA2)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.x))
      .attr("cy", (d) => y(d.y))
      .attr("r", 5)
      .style("fill", "red")
      .on("mouseover", function() { 
        select(this)
        .attr("fill", "rgb(39, 250, 102)")
        .attr("stroke", "green");
        })
      .on("mouseout", function(d) { 
          select(this)
          .attr("fill", "yellow")
          .attr("stroke", "orange")
          });
      ;
  }, []);

  return (
    <div className="App">
      <svg id="area" height={400} width={500}></svg>
    </div>
  );
}



// export default function App() {
//   useEffect(() => {
//     const svg = select("#area");
//     svg
//       .append("circle")
//       .attr("cx", 50)
//       .attr("cy", 50)
//       .attr("r", 40)
//       .style("fill", "blue");
//     svg
//       .append("circle")
//       .attr("cx", 140)
//       .attr("cy", 70)
//       .attr("r", 40)
//       .style("fill", "red");
//     svg
//       .append("circle")
//       .attr("cx", 300)
//       .attr("cy", 100)
//       .attr("r", 40)
//       .style("fill", "green");
//   }, []);

//   return (
//     <div className="App">
//       <svg id="area" height={200} width={450}></svg>
//     </div>
//   );
// }



// export default function App() {
//   const changeStroke = () => {
//     select(".target").style("stroke-width", 5);
//   };

//   return (
//     <div className="App">
//       <button onClick={changeStroke}>change stroke</button>
//       <svg>
//         <circle
//           class="target"
//           style={{ fill: "green" }}
//           stroke="black"
//           cx={50}
//           cy={50}
//           r={40}
//         ></circle>
//       </svg>
//     </div>
//   );
// }


// export default function App() {
//   useEffect(() => {
//     select(".target").style("stroke-width", 5);
//   }, []);
//   return (
//     <div className="App">
//       <svg>
//         <circle
//           class="target"
//           style={{ fill: "green" }}
//           stroke="black"
//           cx={50}
//           cy={50}
//           r={40}
//         ></circle>
//       </svg>
//     </div>
//   );
// }